import { getOneRoleService } from "@api/services/role";
import LoadingComponent from "@components/loading";
import { IRole } from "@interfaces/role";
import { Container, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SingleRoleForm from "./components/form";

const SingleRolePage = () => {
  const id = useParams().id as string;
  const nav = useNavigate();

  const [loading, setLoading] = useState(false);

  const [role, setRole] = useState<IRole | null>(null);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      const response = await getOneRoleService(id);

      if (response.success && response.data) {
        setRole(response.data);
      } else {
        nav("/404");
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [id, nav]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onSuccess = useCallback(() => {
    nav("/roles");
  }, [nav]);

  if (loading) return <LoadingComponent />;

  if (!role) return <></>;

  return (
    <Container maxWidth="xl">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4">{role.name}</Typography>
      </Stack>

      <SingleRoleForm role={role} onSuccess={onSuccess} />
    </Container>
  );
};

export default SingleRolePage;
