// orders.tsx

import { IConsultingFiltersDTO } from "@api/interfaces/api-calls/consulting";
import { listConsultingService } from "@api/services/consulting";
import LoadingComponent from "@components/loading";
import Scrollbar from "@components/scrollbar";
import TableEmptyRows from "@components/table-empty-rows";
import GenricHeadTable from "@components/table/GenricHeadTable";
import { useTableState } from "@hooks/table-state";
import { IConsulting } from "@interfaces/consulting";
import { ITableHeadCell } from "@interfaces/table";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import { emptyRows } from "@utils/empty-rows";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ConsultingTableRow from "./row";

const ConsultingsTable = ({
  filters,
  showDoctor,
  showUser,
}: {
  filters: IConsultingFiltersDTO;
  showDoctor: boolean;
  showUser: boolean;
}) => {
  const { t } = useTranslation("consultings");
  const [loading, setLoading] = React.useState(false);

  const [consultings, setConsultings] = React.useState<IConsulting[]>([]);

  const dispatch = useDispatch<any>();

  const {
    page,
    rowsPerPage,
    handleChangePage,
    totalCount,
    setTotalCount,
    handleChangeRowsPerPage,
    rowsNumberList,
  } = useTableState();

  const getData = React.useCallback(async () => {
    if (!dispatch) return;

    setLoading(true);

    const response = await listConsultingService({
      pageNumber: page + 1,
      pageSize: rowsPerPage,
      ...filters,
    });

    if (response.success && response.data) {
      setConsultings(response.data.consultings);
      setTotalCount(response.data.pagination.totalCount);
    }

    setLoading(false);
  }, [dispatch, page, rowsPerPage, setTotalCount, filters]);

  const columns: ITableHeadCell[] = React.useMemo(() => {
    return [
      {
        id: "identifier",
        key: t("props.identifier"),
      },
      {
        id: "status",
        key: t("props.status"),
      },
      {
        id: "status",
        key: t("props.paymentStatus"),
      },
      {
        id: "Package",
        key: t("props.package"),
      },
      {
        id: "SubDate",
        key: t("props.subscriptionDate"),
      },
      {
        id: "expirationDate",
        key: t("props.expirationDate"),
      },
      {
        id: "sessionStartDate",
        key: t("props.sessionStartDate"),
      },
      ...(showUser ? [{ id: "User", key: t("props.user") }] : []),

      ...(showDoctor
        ? [
            {
              id: "Doctor",
              key: t("props.doctor"),
            },
          ]
        : []),

      { id: "actions", key: "" },
    ];
  }, [showDoctor, showUser, t]);

  React.useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <Scrollbar>
        <TableContainer sx={{ overflow: "unset" }}>
          <Table sx={{ minWidth: 800 }} size="small">
            <GenricHeadTable columns={columns} variant="caption" />

            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={columns.length}>
                    <LoadingComponent />
                  </TableCell>
                </TableRow>
              ) : (
                consultings.map((consulting) => (
                  <ConsultingTableRow
                    consulting={consulting}
                    showUser={showUser}
                    showDoctor={showDoctor}
                  />
                ))
              )}

              <TableEmptyRows
                height={77}
                emptyRows={emptyRows(page, rowsPerPage, consultings.length)}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
        page={page}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        rowsPerPageOptions={rowsNumberList}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default ConsultingsTable;
