import { IUser, UserTitleType } from "@interfaces/user";
import { Box, Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

function TeamMemberTabs({ teamMember }: { teamMember: IUser }) {
  const { t } = useTranslation("users");
  const location = useLocation();
  const navigate = useNavigate();

  const currentTab = location.pathname.split("/").pop() || teamMember._id;

  const handleChange = (_event: any, newValue: string) => {
    navigate(newValue);
  };

  // Construct the tabs array dynamically
  const tabs = [{ label: t("details"), value: teamMember._id }];

  if (
    teamMember?.titleType === UserTitleType.support ||
    teamMember?.titleType === UserTitleType.doctor
  ) {
    tabs.push({ label: t("props.consultings"), value: "consultings" });
  }

  if (teamMember?.titleType === UserTitleType.doctor) {
    tabs.push({ label: t("shifts"), value: "shifts" });
  }

  if (teamMember?.titleType === UserTitleType.employee) {
    tabs.push({ label: t("props.role"), value: "role" });
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={
          tabs.some((tab) => tab.value === currentTab)
            ? currentTab
            : teamMember._id
        }
        onChange={handleChange}
        scrollButtons="auto"
      >
        {tabs.map((tab) => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
    </Box>
  );
}

export default TeamMemberTabs;
