import Iconify from "@components/iconify";
import { IConsulting } from "@interfaces/consulting";

import {
  IconButton,
  MenuItem,
  Popover,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { DisplayConsultingPaymentStatus } from "@views/single-consulting/components/payment-status";
import { DisplayConsultingStatus } from "@views/single-consulting/components/status";
import { AllowedLanguages } from "i18n";

import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

interface OrderTableRowProps {
  consulting: IConsulting;
  showUser: boolean;
  showDoctor: boolean;
}

const ConsultingTableRow: React.FC<OrderTableRowProps> = ({
  consulting,
  showUser,
  showDoctor,
}) => {
  const { t, i18n } = useTranslation("consultings");
  const [open, setOpen] = React.useState(null);

  const handleOpenMenu = (event: any) => {
    if (event) setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const nav = useNavigate();

  return (
    <>
      <TableRow>
        <TableCell>
          <Typography variant="caption">{consulting.identifier}</Typography>
        </TableCell>

        <TableCell>
          <Typography variant="caption">
            <DisplayConsultingStatus status={consulting.status} />
          </Typography>
        </TableCell>

        <TableCell>
          {consulting.paymentStatus && (
            <Typography variant="caption">
              <DisplayConsultingPaymentStatus
                paymentStatus={consulting.paymentStatus}
              />
            </Typography>
          )}
        </TableCell>

        <TableCell>
          <Typography variant="caption">
            {i18n.language === AllowedLanguages.ar
              ? consulting.package?.arTitle
              : consulting.package?.enTitle}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography variant="caption">
            {consulting?.subscriptionDate &&
              moment(consulting.subscriptionDate).format("ll  (h:mm A)")}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography variant="caption">
            {consulting?.expirationDate &&
              moment(consulting.expirationDate).format("ll  (h:mm A)")}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography variant="caption">
            {consulting?.sessionStartDate &&
              moment(consulting.sessionStartDate).format("ll  (h:mm A)")}
          </Typography>
        </TableCell>

        {showUser && (
          <TableCell>
            {consulting.user?._id && (
              <>
                <Typography
                  variant="caption"
                  component={Link}
                  to={`/users/${consulting.user?._id}`}
                >
                  {consulting?.user?.name}
                </Typography>
                <Typography variant="caption" component="div">
                  {consulting?.user?.phone}
                </Typography>
              </>
            )}
          </TableCell>
        )}
        {showDoctor && (
          <TableCell>
            {consulting.doctor?._id && (
              <>
                <Typography
                  variant="caption"
                  component={Link}
                  to={`/team/${consulting.doctor?._id}`}
                >
                  {consulting?.doctor?.name}
                </Typography>
                <Typography variant="caption" component="div">
                  {consulting?.doctor?.phone}
                </Typography>
              </>
            )}
          </TableCell>
        )}

        <TableCell align="right">
          <IconButton onClick={handleOpenMenu}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: { width: 140 },
        }}
      >
        <MenuItem onClick={() => nav(`/consultings/${consulting._id}`)}>
          <Iconify icon="eva:edit-view" sx={{ mr: 2 }} />
          {t("view")}
        </MenuItem>
      </Popover>
    </>
  );
};

export default ConsultingTableRow;
