import axios from "axios";
import { setError, setSuccessMessage } from "../../reducers/alert/alert";
import store from "../../reducers/store";
import apiCallAxios from "./config";

// Define ApiResponse type with overlapping union
export type ApiResponse<T> = {
  success: boolean;
  data?: T;
  message?: string;
};

const apiCall = async <T>(
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH",
  endpoint: string,
  data?: any,
  queryParams?: Record<string, any>
): Promise<ApiResponse<T>> => {
  const response = await apiCallAxios(method, endpoint, data, queryParams);

  if (!response.success) {
    if (response.message) {
      store.dispatch(setError(response.message));
    }
    return { success: false };
  }

  if (method !== "GET" && response.message) {
    store.dispatch(setSuccessMessage(response.message));
  }

  return {
    data: response.data,
    success: true,
    message: response.message,
  };
};

export async function uploadFileApi(
  presignedUrl: string,
  file: File,
  onUploadProgress?: (event: {
    loaded: number;
    total?: number;
    progress?: number;
  }) => any,
  isPublic = false
) {
  try {
    await axios.put(presignedUrl, file, {
      headers: {
        "Content-Type": file.type,
        ...(isPublic
          ? {
              "x-amz-acl": "public-read",
            }
          : {}),
      },
      ...(onUploadProgress
        ? {
            onUploadProgress: (ev) =>
              onUploadProgress({
                loaded: ev.loaded,
                total: ev.total,
                progress: ev.progress,
              }),
          }
        : {}),
    });

    return presignedUrl;
  } catch (error) {
    throw error;
  }
}

export default apiCall;
