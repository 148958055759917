import { IPatchUserShiftDTO } from "@api/interfaces/api-calls/shift";
import {
  deleteUserShiftService,
  patchUserShiftService,
} from "@api/services/users";
import DeleteComponent from "@components/delete-component";
import Iconify from "@components/iconify";
import { IShift, ShiftScheduleType } from "@interfaces/shift";

import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { setGenericSuccessMessage } from "@reducers/alert/alert";
import { doctorTimeZones } from "@utils/doctor-times";
import { FormikProvider, useFormik } from "formik";
import moment from "moment";
import { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

const dayOfWeekNames = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

const shiftSchema = Yup.object().shape({
  scheduleType: Yup.string()
    .oneOf(Object.values(ShiftScheduleType))
    .required("scheduleTypeRequired"),
  startDate: Yup.date().required("startDateRequired"),
  endDate: Yup.date()
    .nullable()
    .test("endDate", "endDateAfterStart", function (endDate) {
      const startDate = this.parent.startDate;
      return !endDate || moment(endDate).isAfter(startDate);
    }),
  activeDays: Yup.array()
    .of(
      Yup.object().shape({
        dayOfWeek: Yup.number().required(),
        shifts: Yup.array()
          .of(
            Yup.object().shape({
              startTime: Yup.string().required("startTimeRequired"),
              endTime: Yup.string()
                .required("endTimeRequired")
                .test("time-order", "endTimeAfterStart", function (endTime) {
                  const startTime = this.parent.startTime;
                  return moment(endTime, "HH:mm").isAfter(
                    moment(startTime, "HH:mm")
                  );
                }),
            })
          )
          .min(1, "atLeastOneShift"),
      })
    )
    .min(1, "atLeastOneDay"),
});

const ShiftTimeInputs = ({
  activeDayIndex,
  shiftIndex,
  formik,
  onDelete,
}: {
  activeDayIndex: number;
  shiftIndex: number;
  formik: any;
  onDelete: () => void;
}) => {
  const { t } = useTranslation("shifts");

  return (
    <Box sx={{ mb: 2, position: "relative" }}>
      <Grid container spacing={2} alignContent="center" alignItems="center">
        <Grid item xs={5}>
          <TextField
            fullWidth
            type="time"
            label={t("startTime")}
            InputLabelProps={{ shrink: true }}
            name={`activeDays.${activeDayIndex}.shifts.${shiftIndex}.startTime`}
            value={
              formik.values.activeDays[activeDayIndex]?.shifts[shiftIndex]
                ?.startTime || ""
            }
            onChange={formik.handleChange}
            error={
              !!formik.errors.activeDays?.[activeDayIndex]?.shifts?.[shiftIndex]
                ?.startTime
            }
            helperText={
              formik.errors.activeDays?.[activeDayIndex]?.shifts?.[shiftIndex]
                ?.startTime &&
              t(
                formik.errors.activeDays[activeDayIndex].shifts[shiftIndex]
                  .startTime
              )
            }
          />
        </Grid>

        <Grid item xs={5}>
          <TextField
            fullWidth
            type="time"
            label={t("endTime")}
            InputLabelProps={{ shrink: true }}
            name={`activeDays.${activeDayIndex}.shifts.${shiftIndex}.endTime`}
            value={
              formik.values.activeDays[activeDayIndex]?.shifts[shiftIndex]
                ?.endTime || ""
            }
            onChange={formik.handleChange}
            error={
              !!formik.errors.activeDays?.[activeDayIndex]?.shifts?.[shiftIndex]
                ?.endTime
            }
            helperText={
              formik.errors.activeDays?.[activeDayIndex]?.shifts?.[shiftIndex]
                ?.endTime &&
              t(
                formik.errors.activeDays[activeDayIndex].shifts[shiftIndex]
                  .endTime
              )
            }
          />
        </Grid>

        <Grid xs={2}>
          <IconButton onClick={onDelete} aria-label={t("deleteShift")}>
            <Iconify icon="eva:trash-2-outline" />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

const DaySchedule = ({
  dayIndex,
  formik,
}: {
  dayIndex: number;
  formik: any;
}) => {
  const { t } = useTranslation("shifts");

  const activeDayIndex = useMemo(
    () =>
      formik.values.activeDays.findIndex((d: any) => d.dayOfWeek === dayIndex),
    [dayIndex, formik.values.activeDays]
  );

  const isActive = useMemo(() => activeDayIndex !== -1, [activeDayIndex]);

  const dayName = t(dayOfWeekNames[dayIndex]);

  const handleToggleDay = useCallback(() => {
    if (isActive) {
      formik.setFieldValue(
        "activeDays",
        formik.values.activeDays.filter(
          (_: any, index: number) => index !== activeDayIndex
        )
      );
    } else {
      formik.setFieldValue("activeDays", [
        ...formik.values.activeDays,
        { dayOfWeek: dayIndex, shifts: [{ startTime: "", endTime: "" }] },
      ]);
    }
  }, [activeDayIndex, dayIndex, formik, isActive]);

  return (
    <Grid item xs={12}>
      <FormControlLabel
        control={<Checkbox checked={isActive} onChange={handleToggleDay} />}
        label={dayName}
      />

      {isActive && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="body2">
            {t("shiftsFor")} {dayName}
          </Typography>
          <Divider sx={{ mb: 2 }} />

          {formik.values.activeDays[activeDayIndex]?.shifts?.map(
            (_: any, shiftIndex: number) => (
              <ShiftTimeInputs
                key={shiftIndex}
                activeDayIndex={activeDayIndex}
                shiftIndex={shiftIndex}
                formik={formik}
                onDelete={() => {
                  const newShifts = formik.values.activeDays[
                    activeDayIndex
                  ].shifts.filter((_: any, i: number) => i !== shiftIndex);
                  formik.setFieldValue(
                    `activeDays.${activeDayIndex}.shifts`,
                    newShifts
                  );
                }}
              />
            )
          )}

          {typeof formik.errors.activeDays?.[activeDayIndex]?.shifts ===
            "string" && (
            <Typography color="error" variant="body2">
              {t(formik.errors.activeDays[activeDayIndex].shifts)}
            </Typography>
          )}

          <Button
            variant="outlined"
            onClick={() => {
              formik.setFieldValue(`activeDays.${activeDayIndex}.shifts`, [
                ...formik.values.activeDays[activeDayIndex].shifts,
                { startTime: "", endTime: "" },
              ]);
            }}
            sx={{ mt: 1 }}
          >
            {t("addShift")}
          </Button>
        </Box>
      )}
    </Grid>
  );
};

const WeekSchedule = ({
  week,
  weekIndex,
  formik,
}: {
  week: number[];
  weekIndex: number;
  formik: any;
}) => {
  const { t } = useTranslation("shifts");

  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h6">
        {t("week")} {weekIndex + 1}
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={2}>
        {week.map((_, dayIndex) => (
          <DaySchedule key={dayIndex} dayIndex={dayIndex} formik={formik} />
        ))}
      </Grid>
    </Box>
  );
};

const ShiftForm: FC<{
  userId: string;
  initValue?: IShift;
  onUpdate?: () => void;
}> = ({ userId, initValue, onUpdate }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("shifts");

  const formik = useFormik<IPatchUserShiftDTO>({
    initialValues: initValue || {
      scheduleType: ShiftScheduleType.weekly,
      startDate: new Date(),
      endDate: null,
      activeDays: [],
      timezone: "",
    },
    validationSchema: shiftSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const { success } = await patchUserShiftService(userId, values);
        if (!success) throw new Error("Not Saved");
        dispatch(setGenericSuccessMessage());
        if (onUpdate) onUpdate();
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
  });

  const deleteShift = async () => {
    try {
      setLoading(true);
      const { success } = await deleteUserShiftService(userId);
      if (!success) throw new Error("Not Saved");
      dispatch(setGenericSuccessMessage());
      if (onUpdate) onUpdate();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getWeeks = useCallback(() => {
    switch (formik.values.scheduleType) {
      case ShiftScheduleType.weekly:
        return [dayOfWeekNames.map((_, i) => i)];
      case ShiftScheduleType.biWeekly:
        return [
          dayOfWeekNames.map((_, i) => i),
          dayOfWeekNames.map((_, i) => i),
        ];
      case ShiftScheduleType.monthly:
        return Array.from({ length: 4 }, () => dayOfWeekNames.map((_, i) => i));
      default:
        return [dayOfWeekNames.map((_, i) => i)];
    }
  }, [formik.values.scheduleType]);

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Card sx={{ p: 2, width: 1 }}>
              <Grid container xs={12} rowSpacing={3} width={1}>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    {t("shiftDetails")}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    value={formik.values.timezone}
                    options={doctorTimeZones}
                    getOptionLabel={(option) => option}
                    onChange={(event, newValue) => {
                      formik.setFieldValue("timezone", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label={t("timezone")}
                        name="timezone"
                        variant="outlined"
                        value={formik.values.timezone}
                        error={
                          formik.touched.timezone &&
                          Boolean(formik.errors.timezone)
                        }
                        helperText={
                          (formik.touched.timezone && formik.errors.timezone) ||
                          " "
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>{t("scheduleType")}</InputLabel>
                    <Select
                      label={t("scheduleType")}
                      name="scheduleType"
                      value={formik.values.scheduleType}
                      onChange={formik.handleChange}
                      error={
                        !!formik.errors.scheduleType &&
                        formik.touched.scheduleType
                      }
                    >
                      {Object.values(ShiftScheduleType).map((type) => (
                        <MenuItem key={type} value={type}>
                          {t(type.toLowerCase())}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <MobileDatePicker
                      label={t("startDate")}
                      value={moment(formik.values.startDate)}
                      onChange={(value) =>
                        formik.setFieldValue("startDate", value?.toDate())
                      }
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error:
                            !!formik.errors.startDate &&
                            !!formik.touched.startDate,
                          helperText:
                            formik.touched.startDate &&
                            (formik.errors.startDate as any),
                        },
                      }}
                      sx={{ mb: 2 }}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <MobileDatePicker
                      label={t("endDate")}
                      value={
                        formik.values.endDate
                          ? moment(formik.values.endDate)
                          : null
                      }
                      onChange={(value) =>
                        formik.setFieldValue("endDate", value?.toDate())
                      }
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error:
                            !!formik.errors.endDate && formik.touched.endDate,
                          helperText:
                            formik.touched.endDate && formik.errors.endDate,
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12} md={8}>
            <Card sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                {t("schedule")}
              </Typography>
              {getWeeks().map((week, weekIndex) => (
                <WeekSchedule
                  key={weekIndex}
                  week={week}
                  weekIndex={weekIndex}
                  formik={formik}
                />
              ))}
            </Card>
          </Grid>
        </Grid>

        {formik.errors.activeDays &&
          typeof formik.errors.activeDays === "string" && (
            <Typography color="error" sx={{ mt: 2 }}>
              {t(formik.errors.activeDays)}
            </Typography>
          )}

        <Grid container spacing={2}>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
              disabled={loading}
            >
              {t("saveSchedule")}
            </Button>
          </Grid>

          <Grid item>
            <DeleteComponent
              onConfirm={deleteShift}
              button={
                <Button variant="contained" sx={{ mt: 2 }} disabled={loading}>
                  {t("deleteSchedule")}
                </Button>
              }
            />
          </Grid>
        </Grid>
      </form>
    </FormikProvider>
  );
};

export default ShiftForm;
