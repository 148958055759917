import { listRolesService } from "@api/services/role";
import { createUserService, updateUserService } from "@api/services/users";
import ImageUploadBox from "@components/upload-image";
import { IRole } from "@interfaces/role";
import { IUser, UserAuthority, UserTitleType } from "@interfaces/user";

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CommunicationWaySelect from "@views/consultings-packages/components/consulting-package-form/comm-ways-select";
import { useFormik } from "formik";
import React, { FC, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
interface Props {
  onSuccess: (user: IUser) => void;
  teamMemberToUpdate?: IUser;
}

const TeamMemberForm: FC<Props> = ({ onSuccess, teamMemberToUpdate }) => {
  const { t: tUsers } = useTranslation("users");
  const { t: tForm } = useTranslation("form");
  const [loading, setLoading] = React.useState(false);
  const [newImage, setNewImage] = React.useState<string | undefined>(undefined);
  const [roles, setRoles] = React.useState<IRole[]>([]);

  const validationSchema = React.useMemo(() => {
    return Yup.object().shape({
      phone: Yup.string()
        .min(
          10,
          tForm("validation.str.min", {
            min: 10,
            path: tUsers("props.phone"),
          })
        )
        .required(
          tForm("validation.required", {
            path: tUsers("props.phone"),
          })
        ),
      ...(!teamMemberToUpdate
        ? {
            password: Yup.string()
              .min(
                8,
                tForm("validation.str.min", {
                  min: 8,
                  path: tUsers("props.password"),
                })
              )
              .required(
                tForm("validation.required", {
                  path: tUsers("props.password"),
                })
              ),
          }
        : {}),

      email: Yup.string()
        .email(
          tForm("validation.str.email", {
            path: tUsers("props.email"),
          })
        )
        .required(
          tForm("validation.required", {
            path: tUsers("props.email"),
          })
        ),

      name: Yup.string().required(
        tForm("validation.required", {
          path: tUsers("props.name"),
        })
      ),

      title: Yup.string().when("titleType", (titleType, schema) => {
        return titleType[0] === UserTitleType.doctor
          ? schema
              .required(
                tForm("validation.required", {
                  path: tUsers("props.title"),
                })
              )
              .max(
                255,
                tForm("validation.required", {
                  path: tUsers("props.title"),
                  max: 255,
                })
              )
          : schema;
      }),

      titleType: Yup.string().required(),

      biography: Yup.string().when("titleType", (titleType, schema) => {
        return titleType[0] === UserTitleType.doctor
          ? schema.required(
              tForm("validation.required", {
                path: tUsers("props.biography"),
              })
            )
          : schema;
      }),

      isVisibleInDoctorsList: Yup.boolean().optional(),
    });
  }, [tForm, tUsers, teamMemberToUpdate]);

  const formik = useFormik({
    initialValues: {
      phone: teamMemberToUpdate?.phone || "",

      password: "",

      email: teamMemberToUpdate?.email || "",

      name: teamMemberToUpdate?.name || "",

      title: teamMemberToUpdate?.title || UserTitleType.doctor,

      titleType: teamMemberToUpdate?.titleType || UserTitleType.doctor,

      biography: teamMemberToUpdate?.biography || "",

      communicationWays: teamMemberToUpdate?.communicationWays,

      isVisibleInDoctorsList:
        teamMemberToUpdate?.isVisibleInDoctorsList ?? false,

      role: teamMemberToUpdate?.role,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const {
          title,

          biography,

          isVisibleInDoctorsList,

          communicationWays,

          titleType,

          role,

          ...data
        } = values;
        setLoading(true);

        const payload: any = {
          ...data,
          image: newImage,
        };

        if (titleType) {
          switch (titleType) {
            case UserTitleType.doctor: {
              payload.title = title;
              payload.biography = biography;
              payload.isVisibleInDoctorsList = isVisibleInDoctorsList;
              payload.communicationWays = communicationWays;
              break;
            }

            case UserTitleType.employee: {
              payload.role = role;
              break;
            }

            case UserTitleType.support: {
              break;
            }
          }
        }

        const response = teamMemberToUpdate
          ? await updateUserService(teamMemberToUpdate._id, payload)
          : await createUserService({
              ...payload,
              authority: UserAuthority.internal,
              titleType,
            });

        if (response.success) {
          if (response.data) onSuccess(response.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
  });

  const fetchRoles = useCallback(async () => {
    setLoading(true);
    try {
      const response = await listRolesService({});
      if (response.data) {
        setRoles(response.data || []);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    console.log(formik.values.titleType);
    if (formik.values.titleType === UserTitleType.employee) {
      fetchRoles();
    }
  }, [formik.values.titleType, fetchRoles]);

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} container rowSpacing={2} columnSpacing={1}>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              justifyContent="center"
              justifyItems="center"
            >
              <ImageUploadBox
                imageUrl={teamMemberToUpdate?.image}
                onUpload={async ({ key }) => {
                  setNewImage(key);
                }}
                maxHeight={150}
                maxWidth={150}
                uploadApi="user"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={tUsers("props.phone")}
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={
                  (formik.touched.phone && formik.errors.phone) || " "
                }
              />
            </Grid>

            {/* Name Field */}
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={tUsers("props.name")}
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={(formik.touched.name && formik.errors.name) || " "}
              />
            </Grid>

            {/* Email Field */}
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={tUsers("props.email")}
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={
                  (formik.touched.email && formik.errors.email) || " "
                }
              />
            </Grid>

            {/* Password Field */}
            {!teamMemberToUpdate && (
              <>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="password"
                    label={tUsers("props.password")}
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      (formik.touched.password && formik.errors.password) || " "
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>{tUsers("props.titleType")}</InputLabel>
                    <Select
                      name="titleType"
                      value={formik.values.titleType}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.titleType &&
                        Boolean(formik.errors.titleType)
                      }
                    >
                      {Object.values(UserTitleType).map((option) => (
                        <MenuItem key={option} value={option}>
                          {tUsers(`titleTypes.${option}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}

            {formik.values?.titleType === UserTitleType.doctor ? (
              <>
                {/* Title Field */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={tUsers("props.title")}
                    name="title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={
                      (formik.touched.title && formik.errors.title) || " "
                    }
                  />
                </Grid>

                {/* Biography Field */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={tUsers("props.biography")}
                    name="biography"
                    value={formik.values.biography}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.biography &&
                      Boolean(formik.errors.biography)
                    }
                    helperText={
                      (formik.touched.biography && formik.errors.biography) ||
                      " "
                    }
                    multiline
                    rows={4}
                  />
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <CommunicationWaySelect
                    communicationWays={formik.values.communicationWays || []}
                    setCommunicationWays={(value) => {
                      formik.setFieldValue("communicationWays", value);
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="isVisibleInDoctorsList"
                        name="isVisibleInDoctorsList"
                        checked={formik.values.isVisibleInDoctorsList}
                        onChange={formik.handleChange}
                      />
                    }
                    label={tUsers("props.isVisibleInDoctorsList")}
                  />
                </Grid>
              </>
            ) : (
              <></>
            )}

            {formik.values?.titleType === UserTitleType.employee ? (
              <>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="role"
                    value={formik.values.role}
                    onChange={formik.handleChange}
                    label={tUsers("selectRole")}
                    select
                  >
                    {roles.map((role) => (
                      <MenuItem key={role._id} value={role._id}>
                        {role.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="inherit"
              disabled={loading}
            >
              {teamMemberToUpdate ? (
                <>
                  {loading
                    ? tUsers("buttons.updating")
                    : tUsers("buttons.update")}
                </>
              ) : (
                <>
                  {loading
                    ? tUsers("buttons.creating")
                    : tUsers("buttons.create")}
                </>
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default TeamMemberForm;
