import { TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { ITableHeadCell } from "../../interfaces/table";

const GenricHeadTable = ({
  columns,
  variant,
}: {
  columns: ITableHeadCell[];
  variant?: "body1" | "body2" | "caption";
}) => {
  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell) => {
          return (
            <TableCell
              key={headCell.id}
              align={headCell.align || "left"}
              sx={{
                width: headCell.width,
                minWidth: headCell.minWidth,
              }}
            >
              <Typography variant={variant || "body2"}>
                {headCell.key}
              </Typography>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default GenricHeadTable;
