import { IUser } from "@interfaces/user";
import { Container, Grid, Typography } from "@mui/material";
import TeamMemberForm from "@views/new-team-member/components/team-member-form/form";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const NewTeamMember = () => {
  const { t } = useTranslation("users");
  const nav = useNavigate();
  const onSuccess = useCallback(
    (teamMember: IUser) => {
      nav(`/team/${teamMember._id}`);
    },
    [nav]
  );
  return (
    <Container maxWidth="xl">
      <Grid container width={1} rowSpacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">{t("newTeamMember")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <TeamMemberForm onSuccess={onSuccess} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default NewTeamMember;
