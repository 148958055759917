export const visuallyHidden: React.CSSProperties = {
  border: 0,
  margin: -1,
  padding: 0,
  width: "1px",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  clip: "rect(0 0 0 0)",
};

export function emptyRows(
  page: number,
  rowsPerPage: number,
  arrayLength: number
): number {
  return page ? Math.max(0, (1 + page) * rowsPerPage - arrayLength) : 0;
}

interface Comparator<T> {
  (a: T, b: T): number;
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
  if (a[orderBy] === null || a[orderBy] === undefined) {
    return 1;
  }
  if (b[orderBy] === null || b[orderBy] === undefined) {
    return -1;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<T>(
  order: "asc" | "desc",
  orderBy: keyof T
): Comparator<T> {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
