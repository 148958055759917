import apiCall, { ApiResponse } from "@api/http";
import { IPaginatedResponse } from "@api/interfaces";
import {
  IConsultingFiltersDTO,
  ICreateConsultingDTO,
  IRescheudleConsultingSessionDTO,
} from "@api/interfaces/api-calls/consulting";
import { ConsultingStatus, IConsulting } from "@interfaces/consulting";

export const listConsultingService = async (
  query: IConsultingFiltersDTO
): Promise<ApiResponse<IPaginatedResponse<IConsulting, "consultings">>> => {
  return await apiCall("GET", "internal/consulting", undefined, query);
};

export const getOneConsultingByIdService = async (
  _id: string
): Promise<ApiResponse<IConsulting>> => {
  return await apiCall("GET", `internal/consulting/${_id}`);
};

export const changeConsultingStatusService = async (
  _id: string,
  status: ConsultingStatus
): Promise<ApiResponse<IConsulting>> => {
  return await apiCall("PUT", `internal/consulting/${_id}/status`, {
    status,
  });
};

export const assignDoctorToConsultingService = async (
  _id: string,
  doctorId: string
): Promise<ApiResponse<IConsulting>> => {
  return await apiCall("PUT", `internal/consulting/${_id}/assign-doctor`, {
    doctorId,
  });
};

export const createConsultingService = async (
  data: ICreateConsultingDTO
): Promise<ApiResponse<IConsulting>> => {
  return await apiCall("POST", "internal/consulting", data);
};

export const reschuedleConsultingSessioService = async (
  data: IRescheudleConsultingSessionDTO
): Promise<ApiResponse<IConsulting>> => {
  return await apiCall(
    "PUT",
    `internal/consulting/${data.consultingId}/re-schedule`,
    {
      sessionStartDate: data.sessionStartDate,
    }
  );
};
