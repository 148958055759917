import { IPatchUserShiftDTO } from "@api/interfaces";
import { IUser } from "../../interfaces/user";
import apiCall, { ApiResponse, uploadFileApi } from "../http";
import {
  GetImagePreSignedURLDTO,
  IPaginatedResponse,
  IPreSignedResponse,
} from "../interfaces/api-calls/shared";

import {
  CreateUserDTO,
  ITopRequestedUsersDTO,
  ITotalUsersDTO,
  ListUsersRequest,
  ToggleDoctorDTO,
  UpdateUserDTO,
} from "../interfaces/api-calls/users";

export const createUserService = async (
  requestData: CreateUserDTO
): Promise<ApiResponse<IUser>> => {
  return await apiCall<IUser>("POST", "internal/user", requestData);
};

export const getUserByIdService = async (
  userId: string
): Promise<ApiResponse<IUser>> => {
  return await apiCall<IUser>("GET", `internal/user/${userId}`);
};

export const updateUserService = async (
  userId: string,
  requestData: UpdateUserDTO
): Promise<ApiResponse<IUser>> => {
  return await apiCall<IUser>("PUT", `internal/user/${userId}`, requestData);
};

export const deleteUserByIdService = async (
  userId: string
): Promise<ApiResponse<number>> => {
  return await apiCall<number>("DELETE", `internal/user/${userId}`);
};

export const listUsersService = async (
  requestData: ListUsersRequest
): Promise<ApiResponse<IPaginatedResponse<IUser, "users">>> => {
  return await apiCall("GET", "internal/user", undefined, requestData);
};

export const getProfileImagePreSignedURL = async (
  dto: GetImagePreSignedURLDTO
): Promise<ApiResponse<IPreSignedResponse>> => {
  return await apiCall<IPreSignedResponse>(
    "GET",
    "user/profile/image-pre-url",
    undefined,
    dto
  );
};

export const uploadUserImage = async (file: File) => {
  const response = await getProfileImagePreSignedURL({
    fileType: file.type,
  });

  if (!response.success || !response.data) {
    return { error: response.message || "Error While Uploading" };
  }

  try {
    await uploadFileApi(response.data.signedURL, file);

    return {
      data: response.data,
    };
  } catch (error) {
    console.error(error);
    return { error: "Couldn't Upload the file" };
  }
};

export const updateUserTitleTypeService = async (
  requestData: ToggleDoctorDTO
): Promise<ApiResponse<IUser>> => {
  return await apiCall<IUser>("PUT", `internal/user/title-type`, requestData);
};

export const getUsersTopRequested = async (
  dto: ITopRequestedUsersDTO
): Promise<
  ApiResponse<
    {
      user: IUser;
      count: number;
    }[]
  >
> => {
  return await apiCall(
    "GET",
    "internal/user/analytics/top-requested",
    undefined,
    dto
  );
};

export const getTotalUsersService = async (
  dto: ITotalUsersDTO
): Promise<ApiResponse<number>> => {
  return await apiCall("GET", "internal/user/analytics/total", undefined, dto);
};

export const patchUserShiftService = async (
  userId: string,
  requestData: IPatchUserShiftDTO
): Promise<ApiResponse<void>> => {
  return await apiCall<void>(
    "PATCH",
    `internal/user/${userId}/shift`,
    requestData
  );
};

export const deleteUserShiftService = async (
  userId: string
): Promise<ApiResponse<void>> => {
  return await apiCall<void>("DELETE", `internal/user/${userId}/shift`);
};
