import { uploadArticleImage } from "@api/services/article";
import { uploadConsultingPackageIcon } from "@api/services/consulting-package";
import { uploadHomeBannerImage } from "@api/services/home-banners";
import { uploadHomeVideoThumbnail } from "@api/services/home-video";
import { uploadUserImage } from "@api/services/users";
import { Box, CircularProgress, Paper } from "@mui/material";
import { setError } from "@reducers/alert/alert";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Iconify from "../iconify";

interface ImageUploadBoxProps {
  imageUrl?: string;
  uploadApi:
    | "user"
    | "consulting-package"
    | "video-thumbnail"
    | "banner-image"
    | "article";
  onUpload: ({
    key,
    previewURL,
    publicURL,
  }: {
    key: string;
    previewURL: string;
    publicURL?: string;
  }) => Promise<void>;

  maxHeight?: number;
  minHeight?: number;
  minWidth?: number | string;
  maxWidth?: number | string;
}

const ImageUploadBox: React.FC<ImageUploadBoxProps> = ({
  imageUrl: raw,
  onUpload,
  maxHeight,
  minHeight,
  uploadApi,
  minWidth,
  maxWidth,
}) => {
  const { t } = useTranslation("form");
  const [loading, setLoading] = React.useState(false);
  const [imageURL, setImageURL] = React.useState(raw);
  const dispatch = useDispatch();

  const handleFileTypeValidation = (file: File) => {
    const isImage = file.type.includes("image");
    const isSvg = file.type.includes("svg+xml");
    return (
      (uploadApi === "consulting-package" || uploadApi === "video-thumbnail"
        ? isImage || isSvg
        : isImage) || isSvg
    );
  };

  const handleUpload = async (file: File) => {
    setLoading(true);
    try {
      let uploadFunction: Function;
      let response: any;

      switch (uploadApi) {
        case "consulting-package":
          if (!handleFileTypeValidation(file)) {
            return dispatch(setError(t("validation.file.shouldBeImage")));
          }
          uploadFunction = uploadConsultingPackageIcon;
          break;
        case "user":
          if (!handleFileTypeValidation(file)) {
            return dispatch(setError(t("validation.file.shouldBeImage")));
          }
          uploadFunction = uploadUserImage;
          break;
        case "video-thumbnail":
          if (!handleFileTypeValidation(file)) {
            return dispatch(setError(t("validation.file.shouldBeImage")));
          }
          uploadFunction = uploadHomeVideoThumbnail;
          break;

        case "banner-image":
          if (!handleFileTypeValidation(file)) {
            return dispatch(setError(t("validation.file.shouldBeImage")));
          }
          uploadFunction = uploadHomeBannerImage;
          break;

        case "article":
          if (!handleFileTypeValidation(file)) {
            return dispatch(setError(t("validation.file.shouldBeImage")));
          }
          uploadFunction = uploadArticleImage;
          break;
        default:
          throw new Error("Unknown upload API");
      }

      response = await uploadFunction(file);
      const { data, error } = response;

      if (error || !data) {
        dispatch(setError(error || "Unknown Error"));
        return;
      }

      const previewURL = URL.createObjectURL(file);
      setImageURL(previewURL);
      await onUpload({ key: data.key, previewURL, publicURL: data.publicURL });
    } catch (error) {
      dispatch(setError("Unknown Error"));
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      handleUpload(file);
    }
  };

  return (
    <Box width={1} height={1} textAlign="center">
      <label
        htmlFor="file-input"
        style={{
          display: "flex",
          alignItems: "center",
          alignContent: "center",
          minHeight: typeof minHeight === "number" ? minHeight : 100,
          maxHeight: typeof maxHeight === "number" ? maxHeight : 250,
          minWidth: typeof minWidth === "number" ? minHeight : undefined,
          maxWidth: typeof maxWidth === "number" ? maxHeight : "100%",
          height: "100%",
          width: "100%",
          marginBottom: "10px",
          cursor: "pointer",
        }}
      >
        <Paper
          sx={{
            width: 1,
            height: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <input
                id="file-input"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <Box
                sx={{
                  width: 1,
                  height: 1,
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                {imageURL ? (
                  <img
                    src={imageURL}
                    alt="Uploaded"
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                  />
                ) : (
                  <Iconify
                    icon="bxs:image-add"
                    sx={{
                      height: "100%",
                      width: "100%",
                    }}
                  />
                )}
              </Box>
            </>
          )}
        </Paper>
      </label>
    </Box>
  );
};

export default ImageUploadBox;
