import { IConsultingPackage } from "./consulting-package";
import { IUser } from "./user";

export interface IMedicine {
  _id: string;
  name: string;
  dosage: string;
  frequencyInterval: number;
  frequencyUnit: "day" | "hour" | "week";
  period: number;
  periodUnit: "month" | "week" | "day";

  // for locale
  readableFrequency?: string;
  readablePeriod?: string;
}

export enum AllowedAttachmentMimeTypes {
  JPEG = "image/jpeg",
  JPG = "image/jpg",
  PNG = "image/png",
  PDF = "application/pdf",
  MPEG = "audio/mpeg",
  MP4 = "audio/mp4",
}

export interface IAttachmentFile {
  key: string;
  fileType: AllowedAttachmentMimeTypes;
  displayType: string;
  url?: string;
}

export enum ConsultingStatus {
  draft = "draft",
  pending = "pending",
  ongoing = "ongoing",
  expired = "expired",
  closed = "closed",
  canceled = "canceled",
}

export enum ConsultingCommunicationWay {
  chat = "chat",
  voice = "voice",
  videoAndVoice = "videoAndVoice",
}

export enum ConsultingCallType {
  voice = "voice",
  video = "video",
}

export enum IConsultingDurationPeriod {
  month = "month",
  week = "week",
  year = "year",
}
export interface IConsultingDuration {
  value: number;
  period: IConsultingDurationPeriod;
}

export enum ConsultingPaymentStatus {
  Pending = "Pending",
  Paid = "Paid",
  Refunded = "Refunded",
  Failed = "Failed",
  Canceled = "Canceled",
}

export interface IConsulting {
  _id: string;

  identifier: string;

  user: string & IUser;

  paymentStatus: ConsultingPaymentStatus;

  doctor: string & IUser;

  package: IConsultingPackage;

  subscriptionDate: Date;

  expirationDate: Date;

  sessionStartDate: Date;

  sessionEndDate: Date;

  ongoingCall: boolean;

  inCallUsers: string[] | IUser[];

  status: ConsultingStatus;

  choosedCommunicationWays: ConsultingCommunicationWay[];

  attachments: IAttachmentFile[];

  subscriptionAdditionalDetails: String;

  plan?: IMedPlan;

  callReminderSentAt?: Date;

  createdAt: Date;

  updatedAt: Date;

  unReadChatMessages?: number;
}

export interface IMedPlan {
  diagnosis: string;
  advices?: string;
  medicines?: IMedicine[];
  diet?: IMedPlanDiet;
}

export interface IMedicalPlanDietMeal {
  title: string;
  description: string;
}

export interface IMedPlanDiet {
  meals: IMedicalPlanDietMeal[];
  restrictions?: string;
  description?: string;
}

export enum ConsultingCreationBy {
  booking = "booking",
  internal = "internal",
}
