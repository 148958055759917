import { Dispatch, SetStateAction, useState } from "react";

type Selected = any[];

type Page = number;

type RowsPerPage = number;

const rowsNumberList = [15, 25, 50];

export const useTableState = (): {
  page: Page;
  setPage: Dispatch<SetStateAction<Page>>;
  selected: Selected;
  setSelected: Dispatch<SetStateAction<Selected>>;
  rowsPerPage: RowsPerPage;
  setRowsPerPage: Dispatch<SetStateAction<RowsPerPage>>;
  handleChangePage: (event: any, newPage: any) => void;
  totalCount: number;
  setTotalCount: any;
  handleChangeRowsPerPage: (event: any) => void;
  rowsNumberList: number[];
} => {
  const [page, setPage] = useState<Page>(0);
  const [totalCount, setTotalCount] = useState(0);
  const [selected, setSelected] = useState<Selected>([]);
  const [rowsPerPage, setRowsPerPage] = useState<RowsPerPage>(rowsNumberList[0]);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  return {
    page,
    setPage,
    selected,
    setSelected,
    rowsPerPage,
    setRowsPerPage,
    handleChangePage,
    totalCount,
    setTotalCount,
    handleChangeRowsPerPage,
    rowsNumberList,
  };
};
