import { getUserByIdService } from "@api/services/users";
import { IUser, UserAuthority } from "@interfaces/user";
import { Container, Grid, Typography } from "@mui/material";
import FullLoadingPage from "@views/loading";
import TeamMemberShift from "@views/team-member/components/team-member-shift";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Outlet,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import TeamMemberConsulting from "./components/consultings";
import TeamMemberDetails from "./components/details";
import EditTeamMemberRole from "./components/edit-role";
import TeamMemberTabs from "./components/tabs";

const TeamMemberPage = () => {
  const id = useParams().id as string;

  const nav = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [teamMember, setTeamMember] = React.useState<IUser | null>(null);
  const { t } = useTranslation("users");
  const fetchUser = React.useCallback(async () => {
    try {
      setLoading(true);

      const response = await getUserByIdService(id);

      if (response.success && response.data) {
        if (response.data.authority !== UserAuthority.internal) {
          nav("/404");
        } else {
          setTeamMember(response.data);
        }
      } else {
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [id]);

  React.useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const onUpdate = React.useCallback((newUser: Partial<IUser>) => {
    setTeamMember((oldUser) => {
      if (!oldUser) return newUser as IUser;
      return { ...oldUser, ...newUser };
    });
  }, []);

  if (loading || !teamMember) return <FullLoadingPage />;

  return (
    <Container maxWidth="xl">
      <Grid item xs={12} container rowSpacing={2}>
        <Grid xs={12} sm={12} item textAlign="center">
          <Typography variant="h6">
            {t("userDetailsWithName", { name: teamMember.name })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TeamMemberTabs teamMember={teamMember} />
        </Grid>

        <Grid item xs={12}>
          <Outlet />
          <Grid item xs={12}>
            <Routes>
              <Route
                path="shifts"
                element={
                  <TeamMemberShift
                    teamMember={teamMember}
                    onUpdate={fetchUser}
                  />
                }
              />

              <Route
                path="consultings"
                element={<TeamMemberConsulting teamMemberId={teamMember._id} />}
              />

              <Route
                path="role"
                element={
                  <EditTeamMemberRole
                    teamMember={teamMember}
                    onSuccess={fetchUser}
                  />
                }
              />

              <Route
                path="*"
                element={
                  <TeamMemberDetails
                    teamMember={teamMember}
                    onUpdate={onUpdate}
                  />
                }
              />
            </Routes>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TeamMemberPage;
