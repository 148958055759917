import { IConsultingFiltersDTO } from "@api/interfaces/api-calls/consulting";
import { Card, Grid } from "@mui/material";
import ConsultingsTable from "@views/consultings/components/table";
import React from "react";

const TeamMemberConsulting = ({ teamMemberId }: { teamMemberId?: string }) => {
  const [filters] = React.useState<IConsultingFiltersDTO>({
    doctor: teamMemberId,
  });

  return (
    <Grid container width={1} rowSpacing={3}>
      <Grid item xs={12}>
        <Card>
          <ConsultingsTable
            filters={filters}
            showUser={true}
            showDoctor={false}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default TeamMemberConsulting;
