import apiCall, { ApiResponse, uploadFileApi } from "@api/http";
import { IPaginatedResponse } from "@api/interfaces";
import {
  GetImagePreSignedURLDTO,
  ICreateArticleDTO,
  IGetAllArticlessDTO,
  IPreSignedResponse,
  IUpdateArticleDTO,
} from "@api/interfaces/api-calls";
import { IArticle } from "@interfaces/article";

const endPoint = `internal/article`;

export const createArticleService = async (
  requestData: ICreateArticleDTO
): Promise<ApiResponse<IArticle>> => {
  return await apiCall<IArticle>("POST", endPoint, requestData);
};

export const updateArticleService = async (
  _id: string,
  requestData: IUpdateArticleDTO
): Promise<ApiResponse<IArticle>> => {
  return await apiCall<IArticle>("PUT", endPoint + `/${_id}`, requestData);
};

export const listArticleService = async (
  requestData: IGetAllArticlessDTO
): Promise<ApiResponse<IPaginatedResponse<IArticle, "articles">>> => {
  return await apiCall("GET", endPoint, undefined, requestData);
};

export const deleteArticleService = async (
  _id: string
): Promise<ApiResponse<IArticle>> => {
  return await apiCall<IArticle>("DELETE", endPoint + `/${_id}`);
};

export const getOneArticleService = async (
  _id: string
): Promise<ApiResponse<IArticle>> => {
  return await apiCall<IArticle>("GET", endPoint + `/${_id}`);
};

export const getArticleImagePreSignedURL = async (
  dto: GetImagePreSignedURLDTO
): Promise<ApiResponse<IPreSignedResponse>> => {
  return await apiCall<IPreSignedResponse>(
    "GET",
    endPoint + "/image-presigned-url",
    undefined,
    dto
  );
};

export const uploadArticleImage = async (file: File) => {
  const response = await getArticleImagePreSignedURL({
    fileType: file.type,
  });

  if (!response.success || !response.data) {
    return { error: response.message || "Error While Uploading" };
  }

  try {
    await uploadFileApi(response.data.signedURL, file, undefined, true);

    return {
      data: response.data,
    };
  } catch (error) {
    console.error(error);
    return { error: "Couldn't Upload the file" };
  }
};
