import { useEffect, useMemo } from "react";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";

import Avatar from "@mui/material/Avatar";
import ListItemButton from "@mui/material/ListItemButton";
import { alpha } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { RouterLink } from "../routes/components";
import { usePathname } from "../routes/hooks";

import Scrollbar from "../components/scrollbar";

import { Tooltip, Zoom } from "@mui/material";
import { useLanguage } from "@theme/language";
import { checkPermissions } from "@utils/checkPermissions";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useResponsive } from "../hooks/use-responsive";
import { RootState } from "../reducers/store";
import { NAV } from "./config-layout";
import navConfig from "./config-navigation";

// ----------------------------------------------------------------------

export default function Nav({
  openNav,
  onCloseNav,
}: {
  openNav: boolean;
  onCloseNav: () => any;
}) {
  const { direction: currentAppDirection } = useLanguage();
  const account = useSelector((state: RootState) => state.auth.user);
  const pathname = usePathname();

  const upLg = useResponsive("up", "lg");

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const navItems = useMemo(() => {
    return navConfig.filter((value) =>
      checkPermissions(value.requiredPermissions, account?.role?.permissions)
    );
  }, [account?.role?.permissions]);

  const renderAccount = (
    <Box
      sx={{
        my: 3,
        mx: 2.5,
        py: 2,
        px: 2.5,
        display: "flex",
        borderRadius: 1.5,
        alignItems: "center",
        bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
      }}
    >
      <Avatar src={account?.image} alt="photoURL" />

      <Box sx={{ ml: 2 }}>
        <Typography variant="subtitle2">{account?.name}</Typography>

        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {account?.role?.name}
        </Typography>
      </Box>
    </Box>
  );

  const renderMenu = (
    <Stack component="nav" spacing={1} sx={{ px: 1, py: 2 }}>
      {navItems.map((item) => (
        <NavItem key={item.title} item={item} />
      ))}
    </Stack>
  );

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      {/*  {renderAccount} */}

      {renderMenu}

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      dir={currentAppDirection}
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.WIDTH },
      }}
    >
      {upLg ? (
        <Box
          sx={{
            height: 1,
            position: "fixed",
            width: NAV.WIDTH,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Box>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.WIDTH,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}

// ----------------------------------------------------------------------

function NavItem({ item }: { item: any }) {
  const { t } = useTranslation("layout");
  const pathname = usePathname();
  const active = item.path === pathname;

  return (
    <Tooltip
      title={t(`nav.${item.title}`)}
      placement="right"
      TransitionComponent={Zoom}
      arrow
    >
      <ListItemButton
        component={RouterLink}
        href={item.path}
        sx={{
          minHeight: 40,
          minWidth: 40,
          justifyContent: "center",
          borderRadius: "50%",
          mx: "auto",
          mb: 1,
          color: "text.secondary",
          ...(active && {
            color: "primary.main",
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
            "&:hover": {
              bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
            },
          }),
          "&:hover": {
            bgcolor: (theme) => alpha(theme.palette.grey[500], 0.08),
          },
        }}
      >
        <Box component="span" sx={{ display: "flex" }}>
          {item.icon}
        </Box>
      </ListItemButton>
    </Tooltip>
  );
}
