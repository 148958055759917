import apiCall, { ApiResponse, uploadFileApi } from "@api/http";
import { IPaginatedResponse } from "@api/interfaces";
import {
  GetImagePreSignedURLDTO,
  ICreateHomeBannerDTO,
  IListHomeBannerDTO,
  IPreSignedResponse,
  IUpdateHomeBannerDTO,
} from "@api/interfaces/api-calls";
import { IHomeBanner } from "@interfaces/home-banner";

const endPoint = `internal/home-banner`;

export const createHomeBannerService = async (
  requestData: ICreateHomeBannerDTO
): Promise<ApiResponse<IHomeBanner>> => {
  return await apiCall<IHomeBanner>("POST", endPoint, requestData);
};

export const updateHomeBannerService = async (
  _id: string,
  requestData: IUpdateHomeBannerDTO
): Promise<ApiResponse<IHomeBanner>> => {
  return await apiCall<IHomeBanner>("PUT", endPoint + `/${_id}`, requestData);
};

export const listHomeBannerService = async (
  requestData: IListHomeBannerDTO
): Promise<ApiResponse<IPaginatedResponse<IHomeBanner, "banners">>> => {
  return await apiCall("GET", endPoint, undefined, requestData);
};

export const deleteHomeBannerService = async (
  _id: string
): Promise<ApiResponse<IHomeBanner>> => {
  return await apiCall<IHomeBanner>("DELETE", endPoint + `/${_id}`);
};

export const getOneHomeBannerService = async (
  _id: string
): Promise<ApiResponse<IHomeBanner>> => {
  return await apiCall<IHomeBanner>("GET", endPoint + `/${_id}`);
};

export const getHomeBannerImagePreSignedURL = async (
  dto: GetImagePreSignedURLDTO
): Promise<ApiResponse<IPreSignedResponse>> => {
  return await apiCall<IPreSignedResponse>(
    "GET",
    endPoint + "/image-presigned-url",
    undefined,
    dto
  );
};

export const uploadHomeBannerImage = async (file: File) => {
  const response = await getHomeBannerImagePreSignedURL({
    fileType: file.type,
  });

  if (!response.success || !response.data) {
    return { error: response.message || "Error While Uploading" };
  }

  try {
    await uploadFileApi(response.data.signedURL, file);

    return {
      data: response.data,
    };
  } catch (error) {
    console.error(error);
    return { error: "Couldn't Upload the file" };
  }
};
