import apiCall, { ApiResponse, uploadFileApi } from "@api/http";
import {
  GetImagePreSignedURLDTO,
  IPaginatedResponse,
  IPreSignedResponse,
} from "@api/interfaces";
import {
  ICreateHomevideoDTO,
  IGetAllVideosDTO,
  IupdateHomevideoDTO,
} from "@api/interfaces/api-calls/home-video";
import { IHomeVideo } from "@interfaces/home-video";
const endPoint = `internal/home-video`;

export const createHomeVideoService = async (
  requestData: ICreateHomevideoDTO
): Promise<ApiResponse<IHomeVideo>> => {
  return await apiCall<IHomeVideo>("POST", endPoint, requestData);
};

export const updateHomeVideoService = async (
  _id: string,
  requestData: IupdateHomevideoDTO
): Promise<ApiResponse<IHomeVideo>> => {
  return await apiCall<IHomeVideo>("PUT", endPoint + `/${_id}`, requestData);
};

export const deleteHomeVideoService = async (
  _id: string
): Promise<ApiResponse<IHomeVideo>> => {
  return await apiCall<IHomeVideo>("DELETE", endPoint + `/${_id}`);
};

export const listHomeVideoService = async (
  requestData: IGetAllVideosDTO
): Promise<ApiResponse<IPaginatedResponse<IHomeVideo, "videos">>> => {
  return await apiCall("GET", endPoint, undefined, requestData);
};

export const getHomeVideoThumbnailPreSignedURL = async (
  dto: GetImagePreSignedURLDTO
): Promise<ApiResponse<IPreSignedResponse>> => {
  return await apiCall<IPreSignedResponse>(
    "GET",
    endPoint + "/thumbnail-presigned-url",
    undefined,
    dto
  );
};

export const uploadHomeVideoThumbnail = async (file: File) => {
  const response = await getHomeVideoThumbnailPreSignedURL({
    fileType: file.type,
  });

  if (!response.success || !response.data) {
    return { error: response.message || "Error While Uploading" };
  }

  try {
    await uploadFileApi(response.data.signedURL, file);

    return {
      data: response.data,
    };
  } catch (error) {
    console.error(error);
    return { error: "Couldn't Upload the file" };
  }
};

export const getHomeVideoPreSignedURL = async (
  dto: GetImagePreSignedURLDTO
): Promise<ApiResponse<IPreSignedResponse>> => {
  return await apiCall<IPreSignedResponse>(
    "GET",
    endPoint + "/video-presigned-url",
    undefined,
    dto
  );
};

export const uploadHomeVideo = async (
  file: File,
  onUploadProgress?: (event: {
    loaded: number;
    total?: number;
    progress?: number;
  }) => any
) => {
  const response = await getHomeVideoPreSignedURL({
    fileType: file.type,
  });

  if (!response.success || !response.data) {
    return { error: response.message || "Error While Uploading" };
  }

  try {
    await uploadFileApi(response.data.signedURL, file, onUploadProgress);

    return {
      data: response.data,
    };
  } catch (error) {
    console.error(error);
    return { error: "Couldn't Upload the file" };
  }
};
