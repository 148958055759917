import Label from "@components/label";
import { ConsultingPaymentStatus } from "@interfaces/consulting";

import React from "react";
import { useTranslation } from "react-i18next";

export const DisplayConsultingPaymentStatus = ({
  paymentStatus,
}: {
  paymentStatus: ConsultingPaymentStatus;
}) => {
  const { t } = useTranslation("consultings");
  const statusLabel = React.useMemo(() => {
    switch (paymentStatus) {
      case ConsultingPaymentStatus.Pending: {
        return { status: ConsultingPaymentStatus.Pending, color: "warning" };
      }

      case ConsultingPaymentStatus.Canceled: {
        return { status: ConsultingPaymentStatus.Canceled, color: "error" };
      }

      case ConsultingPaymentStatus.Failed: {
        return { status: ConsultingPaymentStatus.Failed, color: "error" };
      }

      case ConsultingPaymentStatus.Refunded: {
        return { status: ConsultingPaymentStatus.Refunded, color: "info" };
      }

      case ConsultingPaymentStatus.Paid: {
        return { status: ConsultingPaymentStatus.Paid, color: "success" };
      }
    }
  }, [paymentStatus]);

  return (
    <Label variant="soft" color={statusLabel?.color}>
      {t(`paymentStatus.${paymentStatus}`)}
    </Label>
  );
};
