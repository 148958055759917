import moment from "moment-timezone";
function generateTimeOptions() {
  const timeOptions = [];

  for (let i = 0; i <= 24; i += 0.5) {
    const hours = Math.floor(i);
    const minutes = i % 1 === 0 ? "00" : "30";
    const label = `${String(hours).padStart(2, "0")}:${minutes}`;
    timeOptions.push({ label, value: i });
  }

  return timeOptions;
}

function generateTimeZones() {
  return moment.tz.names();
}

export const doctorTimeZones = generateTimeZones();
export const doctorTimeOptions = generateTimeOptions();
