export enum ShiftScheduleType {
  weekly = "weekly",
  biWeekly = "bi-weekly",
  monthly = "monthly",
}

export enum ShiftDayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export interface IShiftActiveDaySlot {
  startTime: string;
  endTime: string;
}

export interface IShiftActiveDays {
  dayOfWeek: ShiftDayOfWeek;
  shifts: IShiftActiveDaySlot[];
}

export interface IShift {
  _id: string;
  scheduleType: ShiftScheduleType;
  activeDays: IShiftActiveDays[];
  startDate: Date;
  endDate: Date | null;
  timezone: string;
}
