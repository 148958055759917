import Label from "@components/label";
import { ConsultingStatus } from "@interfaces/consulting";

import React from "react";
import { useTranslation } from "react-i18next";

export const DisplayConsultingStatus = ({
  status,
}: {
  status: ConsultingStatus;
}) => {
  const { t } = useTranslation("consultings");
  const statusLabel = React.useMemo(() => {
    switch (status) {
      case ConsultingStatus.pending: {
        return { status: ConsultingStatus.pending, color: "warning" };
      }

      case ConsultingStatus.canceled: {
        return { status: ConsultingStatus.canceled, color: "error" };
      }

      case ConsultingStatus.closed: {
        return { status: ConsultingStatus.closed, color: "error" };
      }

      case ConsultingStatus.expired: {
        return { status: ConsultingStatus.expired, color: "info" };
      }

      case ConsultingStatus.draft: {
        return { status: ConsultingStatus.draft, color: "secondary" };
      }

      case ConsultingStatus.ongoing: {
        return { status: ConsultingStatus.ongoing, color: "success" };
      }
    }
  }, [status]);
  return (
    <Label variant="soft" color={statusLabel?.color}>
      {t(`status.${status}`)}
    </Label>
  );
};
