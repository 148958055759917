import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ConfirmationDialog from "../confirmation-dialog";

const DeleteComponent = ({
  onConfirm,
  button,
}: {
  button: React.ReactElement<{ onClick?: () => void }>; // Explicitly typing button
  onConfirm: () => Promise<any>;
}) => {
  const { t } = useTranslation("common");
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = async () => {
    await onConfirm();
    setOpen(false);
  };
  return (
    <>
      {React.cloneElement(button, {
        onClick: handleOpen, // Inject the onClick handler
      })}
      <ConfirmationDialog
        open={open}
        onClose={handleClose}
        onConfirm={handleConfirm}
        title={t("deleteConfirm")}
        content={t("deleteConfirmAction")}
      />
    </>
  );
};

export default DeleteComponent;
