import {
  assignRoleToUserService,
  deleteRoleFromUserService,
  listRolesService,
} from "@api/services/role";
import LoadingComponent from "@components/loading";
import { IUser } from "@interfaces/user";
import { Button, Grid, MenuItem, TextField } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IRole } from "../../../../interfaces/role";

const EditTeamMemberRole: FC<{
  teamMember: IUser;
  onSuccess: () => void;
}> = ({ teamMember, onSuccess }) => {
  const { t } = useTranslation("users");

  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState<IRole[]>([]);

  const [currentRoleId, setCurrentRoleId] = useState<string | undefined>(
    teamMember.role?._id
  );

  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await listRolesService({});
      if (response.data) {
        setRoles(response.data || []);
      }
    } catch (err) {
      setError("Failed to load roles.");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleRoleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCurrentRoleId(event.target.value as string);
  };

  const updateUserRole = useCallback(async () => {
    try {
      if (currentRoleId === "remove" || !currentRoleId) {
        if (teamMember.role?._id) {
          await deleteRoleFromUserService({
            userId: teamMember._id,
            roleId: teamMember.role?._id,
          });
        }
      } else {
        await assignRoleToUserService({
          userId: teamMember._id,
          roleId: currentRoleId,
        });
      }
      onSuccess();
    } catch (err) {
      setError("Failed to update user role.");
    }
  }, [currentRoleId, teamMember._id, teamMember.role?._id, onSuccess]);

  return (
    <Grid xs={12} item container width={1}>
      {loading ? (
        <LoadingComponent />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              value={currentRoleId || ""}
              onChange={handleRoleChange}
              label={t("selectRole")}
              select
            >
              <MenuItem value="remove">{t("none")}</MenuItem>
              {roles.map((role) => (
                <MenuItem key={role._id} value={role._id}>
                  {role.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {error && (
            <Grid item xs={12}>
              <p style={{ color: "red" }}>{error}</p>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={updateUserRole}
            >
              {t("save")}
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default EditTeamMemberRole;
