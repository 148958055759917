import { IUser } from "@interfaces/user";
import { Grid, Paper } from "@mui/material";
import { setGenericSuccessMessage } from "@reducers/alert/alert";
import TeamMemberForm from "@views/new-team-member/components/team-member-form/form";
import { FC, useCallback } from "react";
import { useDispatch } from "react-redux";

const TeamMemberDetails: FC<{
  teamMember: IUser;
  onUpdate: (newTeamMember: IUser) => void;
}> = ({ teamMember, onUpdate }) => {
  const dispatch = useDispatch();

  const onSuccess = useCallback(
    (teamMember: IUser) => {
      dispatch(setGenericSuccessMessage());
      onUpdate(teamMember);
    },
    [dispatch, onUpdate]
  );

  return (
    <Grid container width={1} item>
      <Paper sx={{ width: 1, p: 2 }}>
        <TeamMemberForm teamMemberToUpdate={teamMember} onSuccess={onSuccess} />
      </Paper>
    </Grid>
  );
};

export default TeamMemberDetails;
