import apiCall, { ApiResponse, uploadFileApi } from "@api/http";
import {
  GetImagePreSignedURLDTO,
  IPaginatedResponse,
  IPreSignedResponse,
} from "@api/interfaces";
import {
  CreateConsultingPackageDTO,
  IListConsultingPackageDTO,
  ITopRequestedPackageDTO,
  UpdateConsultingPackageDTO,
} from "@api/interfaces/api-calls/consulting-package";
import { IConsultingPackage } from "@interfaces/consulting-package";

const endPoint = "internal/consulting-package";

export const listConsultingPackageService = async (
  query: IListConsultingPackageDTO
): Promise<
  ApiResponse<IPaginatedResponse<IConsultingPackage, "consultingPackages">>
> => {
  return await apiCall("GET", `${endPoint}`, undefined, query);
};

export const getOneConsultingPackageByIdService = async (
  _id: string
): Promise<ApiResponse<IConsultingPackage>> => {
  return await apiCall("GET", `${endPoint}/${_id}`);
};

export const deleteConsultingPackageService = async (
  _id: string
): Promise<ApiResponse<IConsultingPackage>> => {
  return await apiCall<IConsultingPackage>("DELETE", endPoint + `/${_id}`);
};

export const createConsultingPackageService = async (
  requestData: CreateConsultingPackageDTO
): Promise<ApiResponse<IConsultingPackage>> => {
  return await apiCall<IConsultingPackage>("POST", endPoint, requestData);
};

export const updateCOnsultingPackageService = async (
  _id: string,
  requestData: UpdateConsultingPackageDTO
): Promise<ApiResponse<IConsultingPackage>> => {
  return await apiCall<IConsultingPackage>(
    "PUT",
    endPoint + `/${_id}`,
    requestData
  );
};

export const getConsultingPackageIconPreSignedURL = async (
  dto: GetImagePreSignedURLDTO
): Promise<ApiResponse<IPreSignedResponse>> => {
  return await apiCall<IPreSignedResponse>(
    "GET",
    endPoint + "/presigned-url",
    undefined,
    dto
  );
};

export const uploadConsultingPackageIcon = async (file: File) => {
  const response = await getConsultingPackageIconPreSignedURL({
    fileType: file.type,
  });

  if (!response.success || !response.data) {
    return { error: response.message || "Error While Uploading" };
  }

  try {
    await uploadFileApi(response.data.signedURL, file);

    return {
      data: response.data,
    };
  } catch (error) {
    console.error(error);
    return { error: "Couldn't Upload the file" };
  }
};

export const getConsultingPackageTopRequested = async (
  dto: ITopRequestedPackageDTO
): Promise<
  ApiResponse<
    {
      package: IConsultingPackage;
      count: number;
    }[]
  >
> => {
  return await apiCall(
    "GET",
    endPoint + "/analytics/top-requested",
    undefined,
    dto
  );
};
