import { TableCell, TableRow } from "@mui/material";

const TableEmptyRows = ({
  height,
  emptyRows,
}: {
  height?: number;
  emptyRows?: number;
}) => {
  if (!emptyRows) {
    return null;
  }

  return (
    <TableRow
      sx={{
        ...(height && {
          height: height * emptyRows,
        }),
      }}
    >
      <TableCell colSpan={9} />
    </TableRow>
  );
};

export default TableEmptyRows;
