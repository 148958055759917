import { IUser } from "@interfaces/user";
import { Container, Grid, Typography } from "@mui/material";

import { FC } from "react";
import { useTranslation } from "react-i18next";
import ShiftForm from "./components/form";

const TeamMemberShift: FC<{ teamMember: IUser; onUpdate?: () => void }> = ({
  teamMember,
  onUpdate,
}) => {
  const { t } = useTranslation("shifts");

  if (!teamMember) {
    return null;
  }

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} textAlign="center" justifyContent="center">
          <Typography variant="h5" component="div">
            {t("updateUserShift", {
              name: teamMember.name,
            })}
          </Typography>

          <Typography variant="body1" component="div">
            {t("shiftSettingsInfo")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ShiftForm
            userId={teamMember._id}
            initValue={teamMember.shift}
            onUpdate={onUpdate}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default TeamMemberShift;
