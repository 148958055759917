import { ConsultingCommunicationWay } from "@interfaces/consulting";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface CommunicationWaySelectProps {
  communicationWays: ConsultingCommunicationWay[] | ConsultingCommunicationWay;
  setCommunicationWays: (
    ways: ConsultingCommunicationWay[] | ConsultingCommunicationWay
  ) => void;
  ways?: any[];
  error?: any;
  helperText?: string;
  isMulti?: boolean; // New prop to toggle between multi and single select
}

const CommunicationWaySelect: React.FC<CommunicationWaySelectProps> = ({
  communicationWays,
  setCommunicationWays,
  ways,
  error,
  helperText,
  isMulti = true, // Default to multi-select
}) => {
  const { t } = useTranslation("consulting-package");

  const handleChange = (
    event: SelectChangeEvent<
      ConsultingCommunicationWay | ConsultingCommunicationWay[]
    >
  ) => {
    const { value } = event.target;

    if (isMulti) {
      setCommunicationWays(
        (typeof value === "string"
          ? value.split(",")
          : value) as ConsultingCommunicationWay[]
      );
    } else {
      setCommunicationWays(value as ConsultingCommunicationWay);
    }
  };

  return (
    <FormControl fullWidth error={error}>
      <InputLabel id="communication-ways-label">
        {t("props.communicationWays")}
      </InputLabel>

      <Select
        label={t("props.communicationWays")}
        labelId="communication-ways-label"
        multiple={isMulti} // Dynamically set based on `isMulti`
        value={communicationWays}
        onChange={handleChange}
        renderValue={(selected) =>
          isMulti
            ? (selected as ConsultingCommunicationWay[])
                .map((val) => t(`communicationWays.${val}`))
                .join(", ")
            : t(`communicationWays.${selected}`)
        }
        error={error}
      >
        {(ways || Object.values(ConsultingCommunicationWay)).map((way) => (
          <MenuItem key={way} value={way}>
            {isMulti && (
              <Checkbox
                checked={
                  isMulti
                    ? (
                        communicationWays as ConsultingCommunicationWay[]
                      ).indexOf(way) > -1
                    : communicationWays === way
                }
              />
            )}
            <ListItemText primary={t(`communicationWays.${way}`)} />
          </MenuItem>
        ))}
      </Select>

      <FormHelperText>{helperText || " "}</FormHelperText>
    </FormControl>
  );
};

export default CommunicationWaySelect;
